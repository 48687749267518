<template>
  <v-row
    :class="
      this.$vuetify.breakpoint.name == 'xs' ||
      this.$vuetify.breakpoint.name == 'sm'
        ? 'px-0'
        : 'px-2'
    "
  >
    <v-col cols="12" class="pa-0">
      <v-breadcrumbs
        class="pl-0 primary--text"
        :items="navigator"
        divider="/"
      ></v-breadcrumbs>
    </v-col>
    <v-col class="pb-1" cols="12">
      <strong class="primary--text devTitleType"
        >JSON Validator & Formatter</strong
      >
    </v-col>

    <v-col v-if="JSONError && JSONText" cols="12" class="pb-0">
      <v-alert type="error">{{ JSONError }} </v-alert>
    </v-col>
    <v-col v-if="JSONSuccess" cols="12" class="pb-0">
      <v-alert type="success"> Valid JSON </v-alert>
    </v-col>
    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="60vh">
        <v-card-title>Input Raw JSON</v-card-title>
        <v-card-text>
          <v-textarea
            background-color="#111111"
            v-model="JSONText"
            rows="10"
            outlined
            placeholder="Enter JSON text here ...."
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="buttonPosition">
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad px-2"
        @click="validateJSON"
        >Validate</v-btn
      >
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="formatJSON"
        >Format</v-btn
      >
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="minifyJSON"
        >Minify</v-btn
      >
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="copyJSON"
        >Copy</v-btn
      >
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="clearJSON"
        >Reset</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="70vh">
        <v-card-title>Output</v-card-title>
        <v-card-text>
          <codemirror
            v-model="code"
            :options="cmOptions"
            :key="refreshContainer"
          />
        </v-card-text>
        <v-card-actions class="ma-3">
          <v-radio-group v-model="jsonEditMode" disabled row class="ma-2">
            <v-radio label="Read Only" :value="true"></v-radio>
            <v-radio label="Edit" :value="false"></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="downloadObjectAsJson(code, 'SampleJSON')"
            >Download JSON</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card tile>
        <v-card-title class="primary--text devTitleType"
          >What is JSON Formatter ?</v-card-title
        >
        <v-card-text class="devTextType"
          >The JSON Formatter is created to help in debugging. As JSON data is
          often output without line breaks, it can be extremely difficult to
          actually read it. This tool formats and beautifies the JSON data so
          that it is easy to read and debug. <br /><br />JSON Formatter and JSON
          Validator help to auto format JSON and validate your JSON text. It
          also provides a tree view that helps to navigate your formatted JSON
          data. <br /><br />
          <v-img :src="TreeViewPNG" max-width="100vh" class="mx-auto" />
          <strong class="primary--text mt-3 ml-3">How to use it ?</strong>
          <ol class="ma-2">
            <li class="my-2">
              <strong class="primary--text">STEP 1 -</strong>
              Paste the RAW JSON data in the input box
            </li>
            <li class="my-2">
              <strong class="primary--text">STEP 2 -</strong>
              Choose the option as per your requirment
              <ul>
                <li><strong> Validate</strong> - validates the JSON</li>
                <li><strong> Format</strong> - Valide + Format the JSON</li>
                <li>
                  <strong>Minify</strong> - remove whitespae and escape
                  character from JSON
                </li>
                <li><strong>Copy</strong> - copy the JSON to the clipboard</li>
                <li><strong>Reset</strong> - Clear the JSON</li>
              </ul>
            </li>
            <li class="my-2">
              <strong class="primary--text">STEP 3 -</strong>
              Customize the Output as per your requirment
              <ul>
                <li><strong>Read Only</strong> - User cannot modify JSON</li>
                <li>
                  <strong> Edit</strong> - User can ADD/REMOVE data in JSON as
                  per his requirment
                </li>
              </ul>
            </li>
            <li class="my-2">
              <strong class="primary--text">STEP 4 -</strong>
              Downloas JSON file to use it somewhere else.
            </li>
          </ol>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import TreeViewPNG from "../../../assets/JSONTreeView.png";
import { codemirror } from "vue-codemirror";
import { scrollToTop } from "@/helpers/helperFunctions.js";
// import language js
import "codemirror/mode/javascript/javascript.js";

// import theme style
import "codemirror/theme/base16-dark.css";

// import more 'codemirror/some-resource...'
import "codemirror/lib/codemirror.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/foldgutter.css";

export default {
  components: {
    codemirror,
  },
  data() {
    return {
      TreeViewPNG,
      refreshContainer: 0,
      JSONError: "",
      JSONSuccess: false,
      jsonEditMode: true,
      navigator: [
        {
          text: "Developer Tools",
          disabled: false,
          href: "",
        },
        {
          text: "Formatter",
          disabled: false,
          href: "",
        },
        {
          text: "JSON Formatter",
          disabled: true,
          href: "json-formatter",
        },
      ],
      JSONText: "",
      code: "",
      cmOptions: {
        tabSize: 4,
        readOnly: true,
        mode: {
          name: "javascript",
          json: true,
          statementIndent: 2,
        },
        theme: "base16-dark",
        lineNumbers: true,
        line: true,
        matchBrackets: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        // more CodeMirror options...
      },
    };
  },
  methods: {
    downloadObjectAsJson(exportObj, exportName) {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(exportObj));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", exportName + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    validateJSON() {
      // reset error
      this.JSONError = "";
      this.code = "";
      this.JSONSuccess = false;

      try {
        // try to parse
        JSON.parse(this.JSONText);
      } catch (e) {
        this.JSONError = JSON.stringify(e.message);
        setTimeout(() => {
          this.JSONError = "";
        }, 3000);
        return 0;
      }
      this.code = this.JSONText;
      this.JSONSuccess = true;
      setTimeout(() => {
        this.JSONSuccess = false;
      }, 3000);
      return 1;
    },
    formatJSON() {
      const valid = this.validateJSON();
      if (valid) {
        this.code = "";
        this.code = JSON.stringify(JSON.parse(this.JSONText), null, 2);
        this.cmOptions.lineWrapping = false;
      }
    },
    copyJSON() {
      navigator.clipboard.writeText(this.code);
    },
    minifyJSON() {
      const valid = this.validateJSON();
      if (valid) {
        this.code = "";
        this.code = JSON.stringify(JSON.parse(this.JSONText));
        this.cmOptions.lineWrapping = true;
      }
    },
    clearJSON() {
      this.code = "";
      this.JSONText = "";
    },
  },
  mounted() {
    scrollToTop();
  },
};
</script>
<style scopped>
.buttonPosition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.btnPad {
  margin-bottom: 20px;
}

@media screen and (max-width: 1260px) {
  .buttonPosition {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .btnPad {
    margin-right: 20px;
  }
}
</style>
